import React, { useState } from 'react'
import * as Theme from './theme'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { config } from './config'

import { UserRoutes } from './routes/user'
import { CreatorRoutes } from './routes/creator'
import { BusinessRoutes } from './routes/business'
import { GlobalStyle } from './components/atoms/GlobalStyle'

// グローバルスタイル設定
export const App: React.FC = () => {
  const [headerToken, setHeaderToken] = useState<string>('')
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: config.apiEndpoint,
      headers: { authorization: headerToken ? `Bearer ${headerToken}` : '' },
    }),
  })

  const storeHeaderToken = (token: string): void => {
    if (token !== headerToken) {
      setHeaderToken(token)
    }
  }

  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={Theme.theme}>
          <GlobalStyle theme={Theme.theme} />
          {/* 一般ユーザー */}
          {document.domain === config.userFrontDomain && <UserRoutes storeHeaderToken={storeHeaderToken} />}
          {/* クリエイター */}
          {document.domain === config.creatorFrontDomain && <CreatorRoutes storeHeaderToken={storeHeaderToken} />}
          {/* ビジネスユーザー */}
          {document.domain === config.businessFrontDomain && <BusinessRoutes storeHeaderToken={storeHeaderToken} />}
        </ThemeProvider>
      </ApolloProvider>
    </CookiesProvider>
  )
}
export default App
