import React, { useCallback, useState, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import {
  TermsOfSale,
  TermsOfUse,
  Privacy,
  NotFound,
  CreatorTop,
  ProductDetail,
  CreatorRanking,
  CreatorSearch,
  CreatorSearchResult,
  ProductsOnSale,
} from '../pages/Common'
import {
  CreatorMypageTop,
  CreatorSalesPerMonth,
  CreatorPurchaseUserRankingPage,
  CreatorRegistration,
  CreatorMypageEdit,
  CreatorProductDelivery,
  CreatorProductRegister,
  CreatorProducts,
  CreatorDisabledProducts,
  CreatorPublishedProducts,
  CreatorReservedProducts,
  CreatorProductDetail,
  CreatorSalesPerOrder,
  CreatorMypageEditComplete,
  CreatorLogin,
  CreatorPasswordReset,
  CreatorPasswordSendComplete,
  CreatorPasswordRenew,
  CreatorPasswordRenewComplete,
  CreatorHome,
  CreatorOrders,
  CreatorLiveTalk,
  CreatorLiveTalkLp,
  CreatorOrderedProductPerUser,
  CreatorOrderedUsers,
} from '../pages/Creator'
import { ScrollToTop } from '../ScrollToTop'
import { Footer, Header } from '../components/molecules'
import { CreatorProfileContext, CreatorTokenContext, initialCreatorProfile, initialCreatorToken } from '../context'
import { Creator } from '../types/graphql'
import { useCookies } from 'react-cookie'
import { userType } from '../lib/UserType'

interface Props {
  storeHeaderToken(token: string): void
}

export const CreatorRoutes: React.FC<Props> = ({ storeHeaderToken }) => {
  const [creatorToken, setCreatorToken] = useState<string>(initialCreatorToken.creatorToken)
  const [creator, setCreator] = useState<Creator>(initialCreatorProfile.creator)
  const updateCreatorToken = useCallback((token) => {
    setCreatorToken(token)
  }, [])
  const updateCreator = useCallback((creator) => {
    setCreator(creator)
  }, [])

  const [cookie] = useCookies(['token', 'creatorToken'])
  const headerToken = creatorToken !== '' ? creatorToken : cookie.creatorToken
  useEffect(() => storeHeaderToken(headerToken), [headerToken, storeHeaderToken])

  if (creatorToken === '') {
    // useContextを優先したいのでブラウザ更新等でtokenが消えた場合はcookieの値をセットしておく
    setCreatorToken(cookie.creatorToken)
  }
  const isCreatorLoggedIn = !!(userType() === 'creator' && (creatorToken || cookie.creatorToken))

  return (
    <CreatorTokenContext.Provider value={{ creatorToken, updateCreatorToken }}>
      <CreatorProfileContext.Provider
        value={{
          creator,
          updateCreator,
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Header isLoggedIn={isCreatorLoggedIn} />
          <main>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/creator',
                    }}
                  />
                )}
              />
              <Route exact path="/creator" render={() => <CreatorHome isLoggedIn={isCreatorLoggedIn} />} />
              <Route exact path="/creator/terms-of-sale" component={TermsOfSale} />
              <Route exact path="/creator/terms-of-use" component={TermsOfUse} />
              <Route exact path="/creator/privacy" component={Privacy} />
              <Route exact path="/creator/login" component={CreatorLogin} />
              <Route exact path="/creator/password/reset" component={CreatorPasswordReset} />
              <Route exact path="/creator/password/send/complete" component={CreatorPasswordSendComplete} />
              <Route exact path="/creator/password/renew" component={CreatorPasswordRenew} />
              <Route exact path="/creator/password/renew/complete" component={CreatorPasswordRenewComplete} />
              <Route exact path="/creator/mypage" component={CreatorMypageTop} />
              <Route exact path="/creator/mypage/edit" component={CreatorMypageEdit} />
              <Route exact path="/creator/mypage/edit/complete" component={CreatorMypageEditComplete} />
              <Route exact path="/creator/product/register" component={CreatorProductRegister} />
              <Route exact path="/creator/sales/:month" component={CreatorSalesPerOrder} />
              <Route exact path="/creator/sales" component={CreatorSalesPerMonth} />
              <Route exact path="/creator/purchase-ranking" component={CreatorPurchaseUserRankingPage} />
              <Route exact path="/creator/registration/:id" component={CreatorRegistration} />
              <Route exact path="/creator/search" component={CreatorSearch} />
              <Route exact path="/creator/search/result" component={CreatorSearchResult} />
              <Route exact path="/creator/product/delivery/:id" component={CreatorProductDelivery} />
              <Route exact path="/creator/products" component={CreatorProducts} />
              <Route exact path="/creator/orders" component={CreatorOrders} />
              <Route exact path="/creator/products/published" component={CreatorPublishedProducts} />
              <Route exact path="/creator/products/disabled" component={CreatorDisabledProducts} />
              <Route exact path="/creator/products/reserved" component={CreatorReservedProducts} />
              <Route exact path="/creator/product/:id" component={CreatorProductDetail} />
              <Route exact path="/creator/:id/user" component={CreatorTop} />
              <Route exact path="/creator/product/:id/user" component={ProductDetail} />
              <Route exact path="/creator/ranking" component={CreatorRanking} />
              <Route
                path="/creator/update_email/verify"
                exact
                render={({ location }) => (
                  <Redirect
                    to={{
                      pathname: '/creator/mypage',
                      search: location.search,
                      state: { from: 'updateEmail' },
                    }}
                  />
                )}
              />
              <Route exact path="/creator/live-talk/:id" component={CreatorLiveTalk} />
              <Route exact path="/creator/lp/live-talk" component={CreatorLiveTalkLp} />
              <Route exact path="/creator/products-on-sale" component={ProductsOnSale} />
              <Route exact path="/creator/ordered-users" component={CreatorOrderedUsers} />
              <Route exact path="/creator/ordered-user/:id" component={CreatorOrderedProductPerUser} />
              <Route component={NotFound} />
            </Switch>
          </main>
          <Footer />
        </BrowserRouter>
      </CreatorProfileContext.Provider>
    </CreatorTokenContext.Provider>
  )
}
