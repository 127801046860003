import React from 'react'
import styled, { css } from 'styled-components'
import defaultIconForLivetalk from '../../../assets/images/icon/default_icon_for_livetalk.png'

type Props = {
  className?: string
  icon?: string
  name?: string
  isUser?: boolean
}

const StyledAbsenceLiveTalkView = styled.div<{ isUser: boolean }>`
  width: calc(100vh * 9 / 16);
  height: 100vh;
  position: relative;
  background-color: ${(props): string => props.theme.backgroundColors.darkGray};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .icon {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: rgba(129, 202, 196, 0.2);
    margin-bottom: 20px;
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
    }
    &::after {
      width: 140px;
      height: 140px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      margin: auto;
      content: '';
      background-color: rgba(129, 202, 196, 0.3);
    }
  }
  p {
    color: ${(props): string => props.theme.textColors.white};
    letter-spacing: 2px;
    text-align: center;
    ${(props) =>
      props.isUser &&
      css`
        span {
          font-weight: bold;
        }
      `}
  }
`

export const AbsenceLiveTalkView: React.FC<Props> = ({ className = '', icon = defaultIconForLivetalk, name, isUser = false }) => {
  return (
    <StyledAbsenceLiveTalkView className={className} isUser={isUser}>
      <div className="icon">
        <img alt="icon" src={icon} />
      </div>
      <p>
        <span>{isUser ? `${name}` : `ユーザー`}</span> さんが参加するまで
        <br />
        このままお待ちください
      </p>
    </StyledAbsenceLiveTalkView>
  )
}
