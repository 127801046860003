import React, { useCallback, useState, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import {
  TermsOfSale,
  TermsOfUse,
  Privacy,
  NotFound,
  CreatorTop,
  ProductDetail,
  CreatorRanking,
  CreatorSearch,
  CreatorSearchResult,
  ProductsOnSale,
} from '../pages/Common'
import {
  BusinessAffiliation,
  BusinessAffiliationDetail,
  BusinessAffiliationSalesPerMonth,
  BusinessCreator,
  BusinessCreatorDetail,
  BusinessCreatorDisabledProducts,
  BusinessCreatorEdit,
  BusinessCreatorEditComplete,
  BusinessCreatorOrders,
  BusinessCreatorProductDetail,
  BusinessCreatorPublishedProducts,
  BusinessCreatorReservedProducts,
  BusinessCreatorSalesPerMonth,
  BusinessCreatorSalesPerOrder,
  BusinessCsvExport,
  BusinessHome,
  BusinessLogin,
  BusinessMypageEdit,
  BusinessMypageEditComplete,
  BusinessMypageTop,
  BusinessPasswordRenew,
  BusinessPasswordRenewComplete,
  BusinessPasswordReset,
  BusinessPasswordSendComplete,
  BusinessProductDelivery,
  BusinessProductRegister,
  BusinessLiveTalk,
} from '../pages/Business'
import { BusinessUserProfileContext, BusinessUserTokenContext, initialBusinessUserProfile, initialBusinessUserToken } from '../context'
import { Footer, Header } from '../components/molecules'
import { ScrollToTop } from '../ScrollToTop'
import { BusinessUser } from '../types/graphql'
import { useCookies } from 'react-cookie'
import { userType } from '../lib/UserType'
import { BusinessCreatorOrderedProductPerUser } from '../pages/Business/Creator/OrderedProductPerUser'
import { BusinessCreatorOrderedUsers } from '../pages/Business/Creator/OrderedUsers'

interface Props {
  storeHeaderToken(token: string): void
}

export const BusinessRoutes: React.FC<Props> = ({ storeHeaderToken }) => {
  const [businessUserToken, setBusinessUserToken] = useState<string>(initialBusinessUserToken.businessUserToken)
  const [businessId, setBusinessId] = useState<BusinessUser['id']>(initialBusinessUserProfile.businessId)
  const [businessName, setBusinessName] = useState<BusinessUser['name']>(initialBusinessUserProfile.businessName)
  const [businessEmail, setBusinessEmail] = useState<BusinessUser['email']>(initialBusinessUserProfile.businessEmail)
  const updateBusinessUserToken = useCallback((token) => {
    setBusinessUserToken(token)
  }, [])
  const updateBusinessId = useCallback((businessId) => {
    setBusinessId(businessId)
  }, [])
  const updateBusinessName = useCallback((businessName) => {
    setBusinessName(businessName)
  }, [])
  const updateBusinessEmail = useCallback((businessEmail) => {
    setBusinessEmail(businessEmail)
  }, [])

  const [cookie] = useCookies(['token', 'businessUserToken'])
  const headerToken = businessUserToken !== '' ? businessUserToken : cookie.businessUserToken
  useEffect(() => storeHeaderToken(headerToken), [headerToken, storeHeaderToken])

  if (businessUserToken === '') {
    // useContextを優先したいのでブラウザ更新等でtokenが消えた場合はcookieの値をセットしておく
    setBusinessUserToken(cookie.businessUserToken)
  }
  const isBusinessLoggedIn = !!(userType() === 'business' && (businessUserToken || cookie.businessUserToken))

  return (
    <BusinessUserTokenContext.Provider value={{ businessUserToken, updateBusinessUserToken }}>
      <BusinessUserProfileContext.Provider
        value={{
          businessId,
          businessName,
          businessEmail,
          updateBusinessId,
          updateBusinessName,
          updateBusinessEmail,
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Header isLoggedIn={isBusinessLoggedIn} />
          <main>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/business',
                    }}
                  />
                )}
              />
              <Route exact path="/business" component={BusinessHome} />
              <Route exact path="/business/terms-of-use" component={TermsOfUse} />
              <Route exact path="/business/terms-of-sale" component={TermsOfSale} />
              <Route exact path="/business/privacy" component={Privacy} />
              <Route exact path="/business/login" component={BusinessLogin} />
              <Route exact path="/business/password/reset" component={BusinessPasswordReset} />
              <Route exact path="/business/password/send/complete" component={BusinessPasswordSendComplete} />
              <Route exact path="/business/password/renew" component={BusinessPasswordRenew} />
              <Route exact path="/business/password/renew/complete" component={BusinessPasswordRenewComplete} />
              <Route exact path="/business/mypage" component={BusinessMypageTop} />
              <Route exact path="/business/mypage/edit" component={BusinessMypageEdit} />
              <Route exact path="/business/mypage/edit/complete" component={BusinessMypageEditComplete} />
              <Route exact path="/business/creator/search" component={CreatorSearch} />
              <Route exact path="/business/creator/ranking" component={CreatorRanking} />
              <Route exact path="/business/products-on-sale" component={ProductsOnSale} />
              <Route exact path="/business/creator/search/result" component={CreatorSearchResult} />
              <Route exact path="/business/creator/:id/user" component={CreatorTop} />
              <Route exact path="/business/creator/:id/orders" component={BusinessCreatorOrders} />
              <Route exact path="/business/creator/:id/edit" component={BusinessCreatorEdit} />
              <Route exact path="/business/creator/:id/edit/complete" component={BusinessCreatorEditComplete} />
              <Route exact path="/business/creator/:id" component={BusinessCreatorDetail} />
              <Route exact path="/business/creator/:id?/product/register" component={BusinessProductRegister} />
              <Route exact path="/business/creator/product/:id" component={BusinessCreatorProductDetail} />
              <Route exact path="/business/affiliation/:id" component={BusinessAffiliationDetail} />
              <Route exact path="/business/affiliation" component={BusinessAffiliation} />
              <Route exact path="/business/creator" component={BusinessCreator} />
              <Route exact path="/business/sales/creator/:id" component={BusinessCreatorSalesPerMonth} />
              <Route exact path="/business/sales/affiliation/:id?" component={BusinessAffiliationSalesPerMonth} />
              <Route exact path="/business/sales/affiliation/creator/:id?/:month" component={BusinessCreatorSalesPerOrder} />
              <Route exact path="/business/sales/csv-export" component={BusinessCsvExport} />
              <Route exact path="/business/product/:id" component={ProductDetail} />
              <Route exact path="/business/product/delivery/:id" component={BusinessProductDelivery} />
              <Route exact path="/business/creator/:id/products/disabled" component={BusinessCreatorDisabledProducts} />
              <Route exact path="/business/creator/:id/products/reserved" component={BusinessCreatorReservedProducts} />
              <Route exact path="/business/creator/:id/products" component={BusinessCreatorPublishedProducts} />
              <Route
                path="/business/update_email/verify"
                exact
                render={({ location }) => (
                  <Redirect
                    to={{
                      pathname: '/business/mypage',
                      search: location.search,
                      state: { from: 'updateEmail' },
                    }}
                  />
                )}
              />
              <Route exact path="/business/live-talk/:id" component={BusinessLiveTalk} />
              <Route exact path="/business/creator/:id/ordered-users" component={BusinessCreatorOrderedUsers} />
              <Route exact path="/business/creator/:id/ordered-user/:user_id" component={BusinessCreatorOrderedProductPerUser} />
              <Route component={NotFound} />
            </Switch>
          </main>
          <Footer />
        </BrowserRouter>
      </BusinessUserProfileContext.Provider>
    </BusinessUserTokenContext.Provider>
  )
}
