import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import * as Sentry from '@sentry/react'
import { LoadingCircle, FlashMessage } from '../../components/atoms'
import { UserHomeTpl } from '../../components/templates'
import { Meta } from '../../Meta'
import {
  CreatorRanking,
  PublishedProduct,
  usePagedUserFavoritedCreatorLatestProductsLazyQuery,
  useValidateUserTokenLazyQuery,
  useCreatorRankingOfMonthlySalesCountLazyQuery,
  useCreatorRankingOfWeeklySalesCountLazyQuery,
  usePagedProductsOnSaleLazyQuery,
  useFavoriteCreatorMutation,
  useUnfavoriteCreatorMutation,
  useUserVerifyAccountMutation,
} from '../../types/graphql'
import { FlashMessageType } from '../../types/myTypes'
import { UserTokenContext } from '../../context'

type Props = { isLoggedIn: boolean }

export const UserHome: React.FC<Props> = ({ isLoggedIn }) => {
  const [searchWord, setSearchWord] = useState<string>('')
  const [creatorRanking, setCreatorRanking] = useState<CreatorRanking[]>([])
  const [favoriteNewProducts, setFavoriteNewProducts] = useState<PublishedProduct[]>([])
  const [productsOnSale, setProductsOnSale] = useState<PublishedProduct[]>([])
  const [selectedDuration, setSelectedDuration] = useState<'weekly' | 'monthly'>('weekly')
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const history = useHistory()
  const location = useLocation<{ from?: string }>()
  const { search, state } = location
  const { updateUserToken } = useContext(UserTokenContext)

  const [, setCookie] = useCookies(['token', 'userToken'])

  const [pagedUserFavoritedCreatorLatestProductsLazyQuery] = usePagedUserFavoritedCreatorLatestProductsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFavoriteNewProducts(data.PagedUserFavoritedCreatorLatestProducts?.data ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [validateUserTokenLazyQuery] = useValidateUserTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.ValidateUserToken === true) {
        pagedUserFavoritedCreatorLatestProductsLazyQuery({
          variables: {
            first: isMobile ? 4 : 10,
          },
        })
      }
    },
    onError: () => {
      setIsTokenValid(false)
    },
  })

  const [creatorRankingOfWeeklySalesCountLazyQuery] = useCreatorRankingOfWeeklySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfWeeklySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })
  const [creatorRankingOfMonthlySalesCountLazyQuery] = useCreatorRankingOfMonthlySalesCountLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false)
      setCreatorRanking(data.CreatorRankingOfMonthlySalesCount ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [pagedProductsOnSaleLazyQuery] = usePagedProductsOnSaleLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductsOnSale(data.PagedProductsOnSale?.data ?? [])
    },
    onError: (e) => {
      setLoading(false)
      setFlashMessage({ type: 'systemError', message: e.message })
      Sentry.captureException(e)
    },
  })

  const [favoriteCreatorMutation] = useFavoriteCreatorMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: 'お気に入り登録しました' })
      pagedUserFavoritedCreatorLatestProductsLazyQuery({
        variables: {
          first: isMobile ? 4 : 10,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchCreatorRanking(selectedDuration)
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り登録できませんでした' })
      }
    },
  })

  const [unfavoriteCreatorMutation] = useUnfavoriteCreatorMutation({
    onCompleted: () => {
      setFlashMessage({ type: 'success', message: 'お気に入り解除しました' })
      pagedUserFavoritedCreatorLatestProductsLazyQuery({
        variables: {
          first: isMobile ? 4 : 10,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchCreatorRanking(selectedDuration)
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: 'お気に入り解除できませんでした' })
      }
    },
  })

  const [userVerifyAccountMutation] = useUserVerifyAccountMutation({
    onCompleted: (data) => {
      const token = data.UserVerifyAccount?.token
      if (token) {
        updateUserToken(token)
        setCookie('userToken', token, { path: '/' })
        setFlashMessage({ type: 'success', message: '登録が完了しました' })
      } else {
        setLoading(false)
        Sentry.captureException('ユーザーの新規登録ができませんでした')
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
    onError: (e) => {
      setLoading(false)
      Sentry.captureException(e)
      if (e.message) {
        setFlashMessage({ type: 'inputError', message: e.message })
      } else {
        setFlashMessage({ type: 'systemError', message: '登録できませんでした' })
      }
    },
  })

  const fetchCreatorRanking = useCallback(
    (type: 'weekly' | 'monthly') => {
      switch (type) {
        case 'weekly':
          creatorRankingOfWeeklySalesCountLazyQuery({
            variables: {
              input: {
                limit: isMobile ? 6 : 10,
              },
            },
          })
          break
        case 'monthly':
          creatorRankingOfMonthlySalesCountLazyQuery({
            variables: {
              input: {
                limit: isMobile ? 6 : 10,
              },
            },
          })
          break
        default:
          break
      }
    },
    [creatorRankingOfMonthlySalesCountLazyQuery, creatorRankingOfWeeklySalesCountLazyQuery],
  )

  const onChangeSearchWord = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchWord(event.target.value)
  }

  const onClickSearchButton = (): void => {
    history.push(`/creators/search/result?keyword=${searchWord}`)
  }

  const onChangeDuration = (durationType: 'weekly' | 'monthly'): void => {
    setSelectedDuration(durationType)
    setLoading(true)
    fetchCreatorRanking(durationType)
  }

  const onClickFavorite = (creatorId: string, isFavorite: boolean): void => {
    setLoading(true)
    setFlashMessage(null)

    if (isFavorite) {
      unfavoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    } else {
      favoriteCreatorMutation({
        variables: {
          creator_id: creatorId,
        },
      })
    }
  }

  useEffect(() => {
    if (state) {
      // 新規登録メール認証URLからのリダイレクト
      if (state.from === 'registerComplete') {
        setLoading(true)
        const params = new URLSearchParams(search)
        const userId = params.get('userId') || null
        const email = params.get('email') || null
        const expires = params.get('expires') || null
        const signature = params.get('signature') || null
        if (userId && email && expires && signature) {
          userVerifyAccountMutation({
            variables: {
              user_id: userId,
              input: {
                email: email,
                expires: Number(expires),
                signature: signature,
              },
            },
          })
        } else {
          setLoading(false)
          Sentry.captureException('ユーザーの新規登録メール認証できませんでした')
          setFlashMessage({ type: 'systemError', message: 'メール認証に失敗しました' })
        }
      }
    }
  }, [search, state, userVerifyAccountMutation])

  useEffect(() => {
    if (isLoggedIn) {
      validateUserTokenLazyQuery()
    }
  }, [isLoggedIn, validateUserTokenLazyQuery])

  useEffect(() => {
    fetchCreatorRanking(selectedDuration)
    pagedProductsOnSaleLazyQuery({
      variables: {
        first: isMobile ? 18 : 30,
      },
    })
  }, [
    fetchCreatorRanking,
    pagedProductsOnSaleLazyQuery,
    pagedUserFavoritedCreatorLatestProductsLazyQuery,
    selectedDuration,
    validateUserTokenLazyQuery,
  ])

  // location.stateがセットされたままだとリロード時に毎回userVerifyAccountMutationを実行してしまうのでreplace
  useEffect(() => {
    if (state) {
      history.replace({ ...location, state: undefined, search: undefined })
    }
  }, [history, location, state])

  return (
    <>
      <Meta />
      {loading && <LoadingCircle />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <UserHomeTpl
        searchWord={searchWord}
        creatorRanking={creatorRanking}
        selectedDuration={selectedDuration}
        favoriteNewProducts={favoriteNewProducts}
        productsOnSale={productsOnSale}
        isLoggedIn={isLoggedIn}
        isTokenValid={isTokenValid}
        onChangeSearchWord={onChangeSearchWord}
        onClickSearchButton={onClickSearchButton}
        onChangeDuration={onChangeDuration}
        onClickFavorite={onClickFavorite}
      />
    </>
  )
}
