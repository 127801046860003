import React from 'react'
import styled from 'styled-components'
import { LiveTalkStatus } from '../../../types/myTypes'

type Props = {
  className?: string
  liveTalkStatus: LiveTalkStatus
  thumbnail: string
}

const StyledNotEnteredTalkRoomView = styled.div`
  height: 100%;
  position: relative;
  .thumbnail {
    position: relative;
    height: 100%;
    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: rgba(34, 34, 34, 0.6);
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  .label {
    position: absolute;
    bottom: 74px;
    width: 100%;
    height: 88px;
    background-color: rgba(129, 202, 196, 0.6);
    color: ${(props): string => props.theme.textColors.white};

    > p {
      font-size: 24px;
      letter-spacing: 0.1em;
      font-weight: bold;
      line-height: 88px;
      text-align: center;
    }

    &.unStarted {
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .status-text {
        margin-bottom: 5px;
        line-height: 28px;
      }

      .status-info {
        font-size: 11px;
        line-height: 13px;
      }
    }

    &.finished {
      background-color: rgba(34, 34, 34, 0.6);
    }
  }
`

export const NotEnteredTalkRoomView: React.FC<Props> = ({ className = '', liveTalkStatus, thumbnail }) => {
  const getText = (liveTalkStatus: LiveTalkStatus): string => {
    switch (liveTalkStatus) {
      case 'connecting':
        return '接続中...'
      case 'finished':
        return '終了しました'
      default:
        return ''
    }
  }

  return (
    <StyledNotEnteredTalkRoomView className={className}>
      <div className="thumbnail">
        <img alt="thumbnail" src={thumbnail} />
      </div>
      <div className={`label ${liveTalkStatus}`}>
        {liveTalkStatus === 'unStarted' ? (
          <>
            <p className="status-text">待機中</p>
            <p className="status-info">ライブトーク開始まで、しばらくお待ちください</p>
          </>
        ) : (
          <p>{getText(liveTalkStatus)}</p>
        )}
      </div>
    </StyledNotEnteredTalkRoomView>
  )
}
