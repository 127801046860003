import { ProductType, RequestCreator, UpdateCreatorAccountByTokenInput } from '../types/graphql'

export type CreatorEditErrors = {
  icon_url: string | null
  name: string | null
  email: string | null
  profile: string | null
  twitter_username: string | null
  instagram_username: string | null
  youtube_channel_url: string | null
  kindle_author_url: string | null
}

export type CreatorRegisterErrors = {
  icon_url: string | null
  name: string | null
  password: string | null
  password_confirm: string | null
  password_match: string | null
  profile: string | null
  twitter_username: string | null
  instagram_username: string | null
  youtube_channel_url: string | null
  kindle_author_url: string | null
}

export type FlashMessageType = {
  type: 'success' | 'inputError' | 'systemError'
  message: string
}

export type ProductRegisterErrors = {
  name: string | null
  thumbnail_image: string | null
  released_at: string | null
}

export type RegisterProductInput = {
  name: string
  price: string
  thumbnail_image: string
  movie_seconds: string
  released_at: string | null
  product_type: ProductType
  start_at: string
  talk_minutes: string
}

export type ThumbnailEdited = {
  original_image: string
  mosaic_image: string
  gaussian_image: string
}

export const ThumbnailEditingTypeEnum = {
  /** 弱い白ぼかし */
  BLUR_WEAK_WHITE: 'blur_weak_white',
  /** 強い白ぼかし */
  BLUR_STRONG_WHITE: 'blur_strong_white',
  /** 弱い黒ぼかし */
  BLUR_WEAK_BLACK: 'blur_weak_black',
  /** 強い黒ぼかし */
  BLUR_STRONG_BLACK: 'blur_strong_black',
} as const
export type ThumbnailEditingType = (typeof ThumbnailEditingTypeEnum)[keyof typeof ThumbnailEditingTypeEnum]

export type ProductListType = 'published' | 'reserved' | 'disabled'

export type UserRegisterErrors = {
  name: string | null
  email: string | null
  password: string | null
  password_confirm: string | null
  password_match: string | null
  twitter_username: string | null
}

export type RegisterUserInput = {
  name: string
  email: string
  password: string
  password_confirm: string
  twitter_username?: string
}

export type UserEditErrors = {
  name: string | null
  email: string | null
  twitter_username: string | null
}

export type paymentType = 'robotpayment' | 'paidy'

export type CreatorRegisterAccountInput = UpdateCreatorAccountByTokenInput & RequestCreator & { password_confirm?: string }

export type CsvExportBy = 'creator' | 'product'

export type LiveTalkStatus = 'unStarted' | 'connecting' | 'talking' | 'finished'

// FIXME　仮
export type RequestProduct = {
  product_type: ProductType
  request_contents: string[]
  other_text: string
  requested_dates: string
}
